import {request} from "@/network/request";
export function getIndexes(){
    return request({
        params: {},
        method:'get',
        url:'/safety/indexes'
    })
}
export function getRanks(){
    return request({
        params: {},
        method:'get',
        url:'/safety/ranks'
    })
}
export function updateRanks(data){
    return request({
        data: data,
        method:'post',
        url:'/safety/updateRanks'
    })
}
export function deleteIndex(data){
    return request({
        params: data,
        method:'post',
        url:'/safety/deleteIndex'
    })
}
export function addIndex(data){
    return request({
        params: data,
        method:'post',
        url:'/safety/addIndex'
    })
}
export function updateIndex(data){
    return request({
        params: data,
        method:'post',
        url:'/safety/updateIndex'
    })
}

export function deleteRank(data){
    return request({
        params: data,
        method:'post',
        url:'/safety/deleteRank'
    })
}
